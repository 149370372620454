:root {
  --size: 16px;
  font-size: var(--size);
  --color01: #000000;
  --color02: #343434;
  --color03: #8e7f90;
  --color04: #3b465d;
  --color04-rgb: 59, 70, 93;
  --color05: #800080;
  --color06: #ff0000;
  --color07: #ffffff;

  --marigin: 1rem 1rem 1rem 1rem;
  --padding: 1rem 0rem 1rem 0rem;
  --border-radious: 1rem;
  --border-radious_only_top: 1rem 1rem 0 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color01);
  width: 100%;
}

/* globalne ustawienia elementów */

::placeholder {
  text-align: center;
  font-size: 0.9rem;
  color: var(--color05);
}

input {
  text-align: center;
  height: 3rem;
}

input[type="number"] {
  width: 4em;
}

input[type="number"]::placeholder {
  color: var(--color05);
  font-size: 0.9rem;
}

input[type="text"] {
  color: var(--color02);
  background-color: var(--color07);
  border-radius: 5px;
  transition: all 0.3s ease;
}

input[type="text"]:hover {
  border-color: var(--color05);
}

input[type="text"]:focus {
  outline: none;
  border-color: var(--color05);
  box-shadow: 0 0 8px rgba(0, 86, 179, 0.2);
}

input[type="checkbox"]:not(:checked) {
  background-color: var(--color05);
  /* Tło checkboxa gdy jest niezaznaczony */
}

input[type="checkbox"] {
  width: 2rem;
  height: 1rem;
  border-radius: 4px;
  cursor: pointer;
}

select {
  height: 3rem;
  text-align: center;
}

textarea {
  width: 100%;
  height: 10rem;
  padding: var(--padding);
  resize: vertical;
  background-size: cover;
  color: var(--color05);
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 1rem;
}

ul {
  list-style-type: none;
  border-radius: 0.25rem
}

button {
  height: 2rem;
  width: 8rem;
  background-color: var(--color01);
  background-image: linear-gradient(to bottom right, var(--color02), var(--color01));
  border: 0px;
  color: var(--color07);
  letter-spacing: 0.15em;
  padding: 0px 1rem;
  cursor: pointer;
  border-radius: var(--border-radious);
  transition-duration: 0.4s;
  font-size: .7rem;
}

button:hover {
  transform: scale(1.05);
  letter-spacing: 0.2em;
  background-color: var(--color02);
  color: var(--color05);
}

.buttonDelete {
  color: var(--color05);
}

.consent {
  font-size: 0.7rem;
}

.error {
  color: var(--color06);
  font-size: 0.6rem;
  display: flex;
  align-items: center;
}

.field-input {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.form-field:active {
  border: 3px solid var(--color03);
}

.form-container {
  padding: 15px;
  justify-items: center;
  justify-content: start;
}

.options-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.option-move {
  margin-top: 5rem;
  margin-left: 1rem;
  position: absolute;
  top: 0;
  left: 0;
}

.option-copy {
  margin-top: 5rem;
  position: absolute;
  top: 0;
  right: 0;
}

.option-delete {
  margin-left: 1rem;
  margin-bottom: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.option-edit {
  margin-bottom: 1rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

.cpf-box {
          color: var(--color05);
          cursor: pointer;
          display: flex;
          flex-Direction: column;
          margin-Bottom: 10px;
}

.cpf-box span {
  font-size: 0.8rem;
  /* margin-Right: 10px; */
  width: 100%;
  text-Align: center 
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--color05);
  color: var(--color07);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  unicode-bidi: isolate;
  border-color: var(--color03);
  border-radius: var(--border-radious_only_top);
  background-color: var(--color01);
  background-image: linear-gradient(to bottom right, var(--color02), var(--color01));
}

thead {
  background-color: var(--color03);
  color: var(--color04);
}

thead tr th:first-child {
  border-top-left-radius: var(--border-radious);
  border-left: 1px var(--color05);
  background-color: var(--color03);
}

thead tr th:last-child {
  border-top-right-radius: var(--border-radious);
  border-right: 1px var(--color05);
  background-color: var(--color03);
}

thead tr th {
  border-bottom: 1px solid var(--color03);
  text-align: center;
  font-size: 0.8rem;
}

tbody tr {
  border-bottom: 1px solid var(--color03);
  font-size: 0.8rem;
}

tbody tr:hover {
  background-color: var(--color05);
}

.fixed-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  cursor: pointer;
  white-space: wrap;
}

.fixed-table td {
  border: 1px solid var(--color03);
  text-align: center;
  vertical-align: middle;
}

.column-small {
  width: max-content;
}

.column-medium {
  width: 15%;
}

.column-large {
  width: 25%;
}

.column-flex {
  width: auto;
}

.navbar {
  background-color: var(--color02);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding);
  flex-wrap: nowrap;
}

.navbar-nav {
  list-style: none;
  display: flex;
  align-items: center;
  padding-left: 0;
  margin-bottom: 0;
  flex-wrap: nowrap;
}

.nav-item {
  margin-right: .2rem;
  margin-left: .2rem;
  display: flex;
  transform-origin: center;
  transition: transform 0.5s ease-in-out;
}

.nav-item:hover {
  transform: rotate(360deg);
}

.nav-link img {
  height: 3rem;
  filter: invert(100%) sepia(4%) saturate(4%) hue-rotate(73deg) brightness(116%) contrast(100%);
  transition: filter 0.3s ease;
}

.nav-link {
  color: var(--color07);
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: 0.1em;
}

.nav-link:hover {
  transform: scale(1.1);
  letter-spacing: 0.15em;
  filter: invert(100%) sepia(40%) saturate(5611%) hue-rotate(287deg) brightness(122%) contrast(121%);
}

.nav-text {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 130%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  transition: opacity 2s ease, visibility 1s ease;
}

.nav-item:hover .nav-text {
  text-decoration: none !important; /* Usuwa podkreślenie */

  opacity: 1;
  visibility: visible;
  filter: invert(100%) sepia(40%) saturate(5611%) hue-rotate(227deg) brightness(10%) contrast(150%);
}

.ml-auto {
  margin-left: auto;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.card-container {
  background-color: var(--color02);
  color: var(--color07);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 10px; 
  border-radius: var(--border-radious);
  margin: auto;
  position: absolute;
  top: 7rem;
}

.custom-file-upload {
  height: 2rem;
  width: 8rem;
  background-color: var(--color01);
  background-image: linear-gradient(to bottom right, var(--color02), var(--color01));
  border: 0px;
  color: var(--color07);
  letter-spacing: 0.15em;
  padding: 0px 1rem;
  border-radius: var(--border-radious);
  cursor: pointer;
  transition-duration: 0.4s;
  font-size: .7rem;
  box-sizing: border-box;
}

.custom-file-upload:hover {
  transform: scale(1.05);
  letter-spacing: 0.2em;
  background-color: var(--color02);
}

.field-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 0 0;
  width: 100%;
}

.field-label img {
  height: 2rem;
  padding: 0 0 0 0;
  width: 2rem;
}

.icon-option img {
  cursor: pointer;
  height: 1.5rem;
  padding: 0 .5rem 0 0;
  transition: transform 0.3s ease;
}

.icon-option img:hover {
  transform: scale(1.2);
}

.icon-option-big img {
  cursor: pointer;
  height: 5rem;
  padding: 0 2rem 0 0;
  transition: transform 0.3s ease;
}

.icon-option-big img:hover {
  transform: scale(1.2);
}


.centered-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.centered-button div {
  padding: 0 0 1rem 0;
}

.collapsible-element {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.collapsible-element span {
  margin-right: 10px;
  width: 200px;
  text-align: right;
}


.Collapsible {
  background-color: var(--color01);
  border-radius: 5px;
  margin: .1rem;
  padding: .2rem;
}

.Collapsible__trigger {
  cursor: pointer;
  font-weight: bold;
}

.Collapsible__trigger.is-open {
  color: var(--color07);
}

.Collapsible__contentInner {
  padding: .3rem;
  background-color: var(--color02);
}

.react-tabs__tab {
  border: 1px solid var(--color03) !important;
  background: var(--color2) !important;
  border-radius: 5px 5px 0 0;
  color: var(--color07);
}

.react-tabs__tab--selected {
  background: var(--color05) !important;
  color: var(--color07) !important;
}

.invitation-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.invitation-item {
  padding: 10px;
  margin-bottom: 5px;
  background-color: var(--color05);
  border-radius: 5px;
}

.invitation-item-empty {
  padding: 10px;
  margin-bottom: 5px;
  background-color: var(--color05);
  border-radius: 5px;
  text-align: center;
}

.single-invitation {
  margin-bottom: 10px;
}

.invitation-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.invitation-actions button {
  background-color: var(--color01);
  color: var(--color01);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.invitation-actions button:hover {
  background-color: var(--color03);
}

/* M O D A L */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999; /* bardzo wysoki priorytet */
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
}

.modal {
  background: rgba( var(--color04-rgb), 0.6);
  border-radius: 8px;
  max-width: 500px;
  min-height: 20rem;
  height: max-content;
  width: 100%;
  padding: 20px;
  position: relative;
  top: 5%;
  z-index: 10000; /* wyższy niż inne elementy */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-body {
  padding: 10px 0;
}

/* SPINNER LOADER */
.loader {
  width: 48px;
  height: 48px;
  border: 5px dotted #FFF;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 4s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}